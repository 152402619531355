.questionnaire-form {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.questionnaire-form h3 {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.questionnaire-form .form-label {
  font-weight: 500;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.questionnaire-form .form-control,
.questionnaire-form .form-select {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  transition: all 0.3s ease;
}

.questionnaire-form .form-control:focus,
.questionnaire-form .form-select:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
}

.questionnaire-form textarea.form-control {
  min-height: 100px;
  resize: vertical;
}

.questionnaire-form .btn {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.questionnaire-form .btn-primary {
  background-color: #3498db;
  border-color: #3498db;
}

.questionnaire-form .btn-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  transform: translateY(-2px);
}

.questionnaire-form .btn-secondary {
  background-color: #95a5a6;
  border-color: #95a5a6;
}

.questionnaire-form .btn-secondary:hover {
  background-color: #7f8c8d;
  border-color: #7f8c8d;
  transform: translateY(-2px);
}

.questionnaire-form .alert {
  border-radius: 8px;
  padding: 1.5rem;
}

/* Progress indicator */
.progress-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.progress-steps::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: #e0e0e0;
  z-index: 1;
}

.step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.step.active {
  border-color: #3498db;
  background: #3498db;
  color: white;
}

.step.completed {
  border-color: #2ecc71;
  background: #2ecc71;
  color: white;
} 